.floating-ancestor {
  position: relative;
}

.floating-container {
  position: absolute;
  top: 10%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}


.floating {
  width: 90%;
  max-width: 300px;
}
@media only screen and (max-width: 768px) {
  .floating {
      max-width: 90%;
  }
}

#found-notification {
  position: relative;
  animation: fadeIn 0.5s;
  top: 0.2rem;

  padding: 0.8rem 1rem;
}

.shown {
  opacity: 1;
  transition: all 250ms linear 0s; 
}

.hidden {
  opacity: 0;
  transition: all 250ms linear 0s; 
}

input.shake{
    animation: shake 0.7s infinite;
}
@keyframes shake {
  0%  { transform: translate(1px, 1px)   rotate(0deg);  }
  10% { transform: translate(-1px, -1px) rotate(-1deg); }
  20% { transform: translate(-1px, 0px)  rotate(1deg);  }
  30% { transform: translate(0px, 1px)   rotate(0deg);  }
  40% { transform: translate(1px, -1px)  rotate(1deg);  }
  50% { transform: translate(-1px, 1px)  rotate(-1deg); }
  60% { transform: translate(-1px, 1px)  rotate(0deg);  }
  70% { transform: translate(1px, 1px)   rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg);  }
  90% { transform: translate(1px, 1px)   rotate(0deg);  }
  100%{ transform: translate(1px, -1px)  rotate(-1deg); }
}
